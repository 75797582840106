import { Component, OnInit, HostBinding } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from "src/app/services/admin.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { TimesheetService } from "src/app/services/timesheet.service";
import { MatSnackBar } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as _moment from "moment";
import { getDateStrFromDateObj } from "../../helper/clientCommonFunction";
import store, { TIMESHEET_ENTRY_LIMIT } from "src/assets/clientConfig";
import { ThrowStmt } from "@angular/compiler";
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
export interface pro {
  value: any;
  proid: any;
}
@Component({
  selector: "app-add-timesheet",
  templateUrl: "./add-timesheet.component.html",
  styleUrls: ["./add-timesheet.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AddTimesheetComponent implements OnInit {
  minDate: any;
  maxDate: any;
  maxtime: any;
  // public minDate: Date = new Date(this.currentYear,this.currentMonth,7,0,0,0);
  // public maxDate: Date = new Date(this.currentYear,this.currentMonth,27,this.currentHour,this.currentMinute,this.currentSecond);
  timesheetForm: FormGroup;
  @HostBinding("class") classes = "row";
  pros: pro[] = [];
  arr = [];
  dateLimit = store.dateRecord.lastDay;
  proid: number;
  userid = window.sessionStorage.getItem("userid");
  calcDuration: string = "00:00";
  submitted = false;
  timesheet: any = {
    timesheet_id: 0,
    project_id: 0,
    user_id: "",
    created_date: "",
    updated_date: "",
    duration: 0,
    approved: 1,
    description: "",
    start_time: "",
    end_time: "",
    entry_date: new Date(),
  };

  endTimeLimit: any;

  // Our changes
  currentDateTime = new Date();
  startTimePickerMinimum: string = null;
  startTimePickerMaximum: string = this.convertDateToTimePickerFormat(
    this.currentDateTime
  );
  endTimePickerMinimum: string = null;
  endTimePickerMaximum: string = this.convertDateToTimePickerFormat(
    this.currentDateTime
  );

  edit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private timesheetService: TimesheetService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    this.getServerTime();
  }

  getServerTime() {
    this.timesheet.start_time = new Date().toLocaleTimeString("en-GB");
    this.timesheet.end_time = new Date().toLocaleTimeString("en-GB");
    this.timesheet.created_date = new Date();
    this.timesheet.updated_date = new Date();
  }

  // Handler function which is triggered when the date is changed
  changedDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.timesheet.date = event.value;
    var check = moment(this.timesheet.date, "DD/MM/YYYY");
    var day = check.format("DD");
    var month = check.format("MM");
    var year = check.format("YYYY");
    this.timesheet.entry_date = year + "-" + month + "-" + day;
    const timesheetDate = new Date(this.timesheet.date);

    this.timesheet.start_time = "";
    this.timesheet.end_time = "";

    this.handleSelectedDateChange(timesheetDate);
  }

  openSnackBar(msg, dur) {
    this.snackBar.open(msg, "Close", {
      duration: dur,
    });
  }

  ngOnInit() {
    this.edit = false;
    this.timesheetForm = this.formBuilder.group({
      description: [""],
      projectid: ["", Validators.required],
      date: ["", Validators.required],
      start: ["", Validators.required],
      end: ["", Validators.required],
      duration: [{ value: "", disabled: true }, Validators.required],
    });
    const params = this.activatedRoute.snapshot.params;
    if (params.id) {
      this.timesheetService.getTimesheet(params.id).subscribe(
        (res) => {
          if (res["statusCode"] == 501) {
            this.openSnackBar(res["message"], 2000);
            this.router.navigate(["/timesheet"]);
          } else {
            if (res["statusCode"] == undefined || res["statusCode"] == 200) {
              res = res["data"];
              res["start_time"] = new Date(
                res["start_time"]
              ).toLocaleTimeString("en-GB");
              res["end_time"] = new Date(res["end_time"]).toLocaleTimeString(
                "en-GB"
              );
              res["entry_date"] = res["entry_date"].substring(0, 10);
              this.edit = true;
              this.getDateList();
              this.timesheet = res;
              this.timesheetForm
                .get("projectid")
                .setValue(this.timesheet.project_id);
              this.timesheet.date = res["entry_date"];
              this.displayDuration();
            }
            if (res["statusCode"] == "401") {
              localStorage.clear();
              this.router.navigate(["/login"]);
            }
          }
        },
        (err) => console.error(err)
      );
    } else this.getDateList();
  }

  get f() {
    return this.timesheetForm.controls;
  }

  getProjects(st, end) {
    var jsonObj = {};
    jsonObj["user_id"] = this.userid;
    jsonObj["start_date"] = st;
    jsonObj["end_date"] = end;
    jsonObj["action"] = "timesheet";
    this.authService.getAllProjForReportOrPlanning(jsonObj).subscribe(
      (res) => {
        if (res["statusCode"] == "200") {
          res = res["data"];
          var len = res.length;
          this.pros = [];
          if (len > 0) {
            for (var i = 0; i < len; i++) {
              if (res[i]["project_code"].split("-")[0] == "p") {
                this.pros.push({
                  value:
                    res[i]["project_name"] + "_" + res[i]["project_code_old"],
                  proid: res[i]["id"],
                });
              } else {
                this.pros.push({
                  value: res[i]["project_name"] + "_" + res[i]["project_code"],
                  proid: res[i]["id"],
                });
              }
            }
          } else if (len == 0) {
            this.openSnackBar("No Projects Available!", 2000);
            setTimeout(() => {
              this.router.navigate(["/timesheet"]);
            }, 2010);
          }
        }
        if (res["statusCode"] == "500") {
          this.openSnackBar(res["message"], 2000);
          setTimeout(() => {
            this.router.navigate(["/timesheet"]);
          }, 2010);
        }
        if (res["statusCode"] == "401") {
          localStorage.clear();
          this.router.navigate(["/login"]);
        }
      },
      (err) => console.error(err)
    );
    return;
  }

  getDateList() {
    this.timesheetService.getDatesToFillTS().subscribe(
      (res) => {
        if (res["statusCode"] == "200") {
          res = res["data"];
          this.minDate = res["from"].substring(0, 10);
          this.maxDate = res["to"].substring(0, 10);
          if (!this.edit) {
            this.timesheet.date = res["to"].substring(0, 10);
            this.timesheet.entry_date = this.timesheet.date;
          }

          this.getProjects(this.minDate, this.maxDate);
        }
        if (res["statusCode"] == "501") {
          this.openSnackBar("Date list Error", 5000);
        }
        if (res["statusCode"] == "500") {
          this.openSnackBar("Error occured while getting date list", 5000);
        }
        if (res["statusCode"] == "401") {
          localStorage.clear();
          this.router.navigate(["/login"]);
        }
      },
      (err) => console.error(err)
    );
    return;
  }

  selectproid(proid) {
    this.proid = proid;
    this.timesheet.project_id = proid;
  }
  timeEvent(event: any, time: any) {
    if (time === "endTime") {
      //convert event value to date
      let date = new Date(event.target.value);
      //add 1 hour
      date.setHours(date.getHours() + 4);
    }
  }

  compareTimes() {
    if (this.timesheet.start_time.split(":")[2] == undefined)
      this.timesheet.start_time = this.timesheet.start_time + ":00";
    if (this.timesheet.end_time.split(":")[2] == undefined) {
      this.timesheet.end_time = this.timesheet.end_time + ":00";
    }

    var timeComp = this.timesheet.start_time.localeCompare(
      this.timesheet.end_time
    );

    this.handleSelectedTimeChange();

    // ! Can't be refactored because of dependency
    if (timeComp == 1 || timeComp == 0) {
      this.openSnackBar("End time should be greater than Start time!", 2000);
      this.timesheet.end_time = this.timesheet.start_time;
      this.calcDuration = "";
    } else {
      this.getDuration();
      this.displayDuration();
    }
  }

  getDuration() {
    var p = this.timesheet.end_time;
    var n = this.timesheet.start_time;
    var hms = n;
    var a = hms.split(":");
    var seconds = +a[0] * 60 * 60 + +a[1] * 60;
    var hm = p;
    var b = hm.split(":");
    var second = +b[0] * 60 * 60 + +b[1] * 60;
    var result = second - seconds;
    this.timesheet.duration = result;
  }

  displayDuration() {
    var d = this.timesheet.duration;
    const HOUR = 60 * 60;
    const MINUTE = 60;
    var minutesInSeconds = d % HOUR;
    var hours = Math.floor(d / HOUR);
    var minutes = Math.floor(minutesInSeconds / MINUTE);
    var hr, min;
    if (hours < 10) {
      hr = "" + "0" + hours;
    } else {
      hr = "" + hours;
    }
    if (minutes < 10) {
      min = "" + "0" + minutes;
    } else {
      min = "" + minutes;
    }
    var dur = hr + ":" + min;
    this.calcDuration = dur;
    return dur;
  }

  saveNewTimesheet() {
    this.submitted = true;
    if (this.timesheetForm.invalid) {
      return;
    } else {
      var timeComp = this.timesheet.start_time.localeCompare(
        this.timesheet.end_time
      );
      if (timeComp == 0 || timeComp == 1) {
        this.openSnackBar("End time should be greater than Start time!", 5000);
        this.timesheet.end_time = "00:00";
        this.calcDuration = "";
      } else {
        if (typeof this.timesheet.entry_date == "object")
          this.timesheet.entry_date = new Date(
            getDateStrFromDateObj(this.timesheet.entry_date)
          );
        else this.timesheet.entry_date = new Date(this.timesheet.entry_date);

        delete this.timesheet.timesheet_id;
        this.timesheet.project_id = this.proid;
        this.timesheet.user_id = this.userid;
        if (this.timesheet.start_time.split(":")[2] == undefined)
          this.timesheet.start_time = this.timesheet.start_time + ":00";
        if (this.timesheet.end_time.split(":")[2] == undefined)
          this.timesheet.end_time = this.timesheet.end_time + ":00";

        this.timesheet["actualStartTime"] = new Date(
          getDateStrFromDateObj(this.timesheet["entry_date"]) +
            " " +
            this.timesheet["start_time"]
        );
        this.timesheet["actualEndTime"] = new Date(
          getDateStrFromDateObj(this.timesheet["entry_date"]) +
            " " +
            this.timesheet["end_time"]
        );

        this.timesheetService.saveTimesheet(this.timesheet).subscribe(
          (res) => {
            if (res["statusCode"] == undefined || res["statusCode"] == "200") {
              this.openSnackBar("Timesheet is created successfully", 5000);
              setTimeout(() => {
                this.router.navigate(["/timesheet"]);
              }, 500);
            }
            if (res["statusCode"] == undefined || res["statusCode"] == "501") {
              this.openSnackBar(res["message"], 5000);
            }
            if (res["statusCode"] == undefined || res["statusCode"] == "500") {
              this.openSnackBar("Error occured while creating Timesheet", 5000);
            }
            if (res["statusCode"] == "401") {
              localStorage.clear();
              this.router.navigate(["/login"]);
            }
          },
          (err) => console.error(err)
        );
      }
    }
  }

  updateTimesheet() {
    this.submitted = true;
    if (this.timesheetForm.invalid) {
      this.openSnackBar("Error occured while creating Timesheet", 5000);
      return;
    } else {
      if (typeof this.timesheet.entry_date == "object")
        this.timesheet.entry_date = new Date(
          getDateStrFromDateObj(this.timesheet.entry_date)
        );
      else this.timesheet.entry_date = new Date(this.timesheet.entry_date);

      if (this.timesheet.start_time.split(":")[2] == undefined)
        this.timesheet.start_time = this.timesheet.start_time + ":00";
      if (this.timesheet.end_time.split(":")[2] == undefined)
        this.timesheet.end_time = this.timesheet.end_time + ":00";

      this.timesheet["actualStartTime"] = new Date(
        getDateStrFromDateObj(this.timesheet["entry_date"]) +
          " " +
          this.timesheet["start_time"]
      );
      this.timesheet["actualEndTime"] = new Date(
        getDateStrFromDateObj(this.timesheet["entry_date"]) +
          " " +
          this.timesheet["end_time"]
      );

      this.timesheetService
        .updateTimesheet(this.timesheet.timesheet_id, this.timesheet)
        .subscribe(
          (res) => {
            if (res["statusCode"] == undefined || res["statusCode"] == "200") {
              this.openSnackBar("Timesheet is updated successfully", 5000);
              setTimeout(() => {
                this.router.navigate(["/timesheet"]);
              }, 500);
            }
            if (res["statusCode"] == undefined || res["statusCode"] == "501") {
              this.openSnackBar(
                "Timesheet already exist,Please check start and end time",
                5000
              );
            }
            if (res["statusCode"] == undefined || res["statusCode"] == "500") {
              this.openSnackBar("Error occured while creating Timesheet", 5000);
            }
            if (res["statusCode"] == "401") {
              localStorage.clear();
              this.router.navigate(["/login"]);
            }
          },
          (err) => console.error(err)
        );
    }
  }

  // Utility functions

  /**
   * @function
   * @name convertDateToTimePickerFormat
   * @description Converts a JS Date object to a string in HH:MM:SS format
   * @param date { Date } JS Date object
   * @returns { string } Time in HH:MM:SS format
   */
  convertDateToTimePickerFormat(date: Date): string {
    return date.toTimeString().split(" ")[0];
  }

  /**
   * @function
   * @name handleSelectedDateChange
   * @description Handles the change in date
   * @param selectedDate { Date } JS Date Object
   */
  handleSelectedDateChange(selectedDate: Date) {
    const currentDate = new Date();
    const currentDateAtMidnight = new Date(
      new Date(currentDate).setHours(0, 0, 0, 0)
    );

    // If the selected date is less than the current date
    if (selectedDate.setHours(0, 0, 0, 0) < currentDateAtMidnight.valueOf()) {
      console.log(this.currentDateTime);
      // Setting start and end time to zero hours
      this.timesheet.start_time =
        this.convertDateToTimePickerFormat(selectedDate);
      this.timesheet.end_time =
        this.convertDateToTimePickerFormat(selectedDate);

      // Removing all restrictions from time picker
      this.startTimePickerMinimum = null;
      this.startTimePickerMaximum = null;
      this.endTimePickerMinimum = null;
      this.endTimePickerMaximum = null;
    } else {
      // Setting start and end time to current time
      this.timesheet.start_time =
        this.convertDateToTimePickerFormat(currentDate);
      this.timesheet.end_time = this.convertDateToTimePickerFormat(currentDate);

      // Adding restrictions to time picker
      this.startTimePickerMaximum =
        this.convertDateToTimePickerFormat(currentDate);
      this.endTimePickerMaximum =
        this.convertDateToTimePickerFormat(currentDate);
    }
  }

  /**
   * @function
   * @name handleSelectedTimeChange
   * @description Handles the change in time
   */
  handleSelectedTimeChange() {
    const currentDate = new Date();
    const currentDateAtMidnight = new Date(
      new Date(currentDate).setHours(0, 0, 0, 0)
    );
    const selectedDate = new Date(
      new Date(this.timesheet.date).setHours(0, 0, 0)
    );

    if (this.timesheet.start_time) {
      // Setting end time restrictions
      this.endTimePickerMinimum = this.timesheet.start_time;

      this.endTimePickerMaximum = this.computeLimitingHours(
        this.timesheet.start_time,
        "add"
      );

      // This is only triggered for current date, because we are not allowing entries in future
      if (selectedDate.valueOf() === currentDateAtMidnight.valueOf()) {
        this.endTimePickerMaximum = this.getMinimumTime(
          this.convertDateToTimePickerFormat(currentDate),
          this.endTimePickerMaximum
        );
      }

      // If the current value of end time is already higher than the limit
      // Then, set it back to the limit amount
      if (
        this.getMinimumTime(
          this.timesheet.end_time,
          this.endTimePickerMaximum
        ) === this.endTimePickerMaximum
      ) {
        this.openSnackBar("Time range auto-adjusted", 2000);
        this.timesheet.end_time = this.endTimePickerMaximum;
      }
    }
  }

  /**
   * @function
   * @name computeLimitingHours
   * @description Computes the limiting time for a given time
   * @param time { string } Time in HH:MM:SS format
   * @param type { string } Type of operation to be performed. Can be "add" or "subtract"
   * @param limit { number } Limit in seconds. Default value is {@link TIMESHEET_ENTRY_LIMIT}
   * @returns { string } Time in HH:MM:SS format
   */
  computeLimitingHours(
    time: string,
    type: string = "add",
    limit: number = TIMESHEET_ENTRY_LIMIT
  ): string {
    // Splitting the time string by :
    let timeSplitted = time.split(":");

    // Getting the number of hours and minutes from it.
    let hours = timeSplitted[0];
    let minutes = timeSplitted[1];

    // Converting them to seconds and adding or subtracting the limit to it as per parameter
    let timeInSeconds = 0;
    if (type === "add") {
      timeInSeconds =
        parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + limit;

      // If the time is greater than 24 hours, then set it to 24 hours
      if (timeInSeconds > 24 * 60 * 60) {
        timeInSeconds = 24 * 60 * 60 - 1;
      }
    } else if (type === "subtract") {
      timeInSeconds =
        parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 - limit;
    }

    // Creating a new date object and setting the time to the limit time.
    let limitTime = new Date();
    limitTime.setHours(0, 0, 0, 0);
    limitTime.setSeconds(timeInSeconds);

    console.log(this.convertDateToTimePickerFormat(limitTime));

    // Getting the time part out of it
    return this.convertDateToTimePickerFormat(limitTime);
  }

  /**
   * @function
   * @name getMinimumTime
   * @description Gets the minimum time out of two times
   * @param time01 { string } Time in HH:MM:SS format
   * @param time02 { string } Time in HH:MM:SS format
   * @returns { string } Time in HH:MM:SS format
   */
  getMinimumTime(time01: string, time02: string): string {
    let time01Splitted = time01.split(":");
    let time02Splitted = time02.split(":");

    let time01InSeconds =
      parseInt(time01Splitted[0]) * 60 * 60 +
      parseInt(time01Splitted[1]) * 60 +
      parseInt(time01Splitted[2]);
    let time02InSeconds =
      parseInt(time02Splitted[0]) * 60 * 60 +
      parseInt(time02Splitted[1]) * 60 +
      parseInt(time02Splitted[2]);

    return time01InSeconds < time02InSeconds ? time01 : time02;
  }
}
