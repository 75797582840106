export default {
  dateRecord: {
    lastDay: 1,
  },
  empGrpList: [
    {
      id: 1,
      value: "Billable",
    },
    {
      id: 2,
      value: "Interns",
    },
    {
      id: 3,
      value: "Non-Billable",
    },
  ],
};

// Defines the time sheet entry limit in seconds
export const TIMESHEET_ENTRY_LIMIT: number = 4 * 60 * 60;
